.block_user {
  width: 96%;
  height: 45px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(139, 147, 162, 0.6),
    0px 0px 1px 0px rgba(139, 147, 162, 0.2);
  display: flex;
  align-items: center;

  .ano {
    color: #61708b;
    font-size: 14px;
    font-weight: 400;
    margin: 0 11px 0 15px;
  }

  .name-type {
    display: flex;
    flex-direction: column;
  }

  .name {
    display: inline-block;
    color: #4e6574;
    font-size: 16px;
    font-weight: 500;
    margin-right: 3px;
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .person_type {
    color: #4e6574;
    font-size: 12px;
    font-weight: 500;
  }

  .block_right {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .flag {
    width: 25px;
  }

  .genero {
    margin: 0 10px;
  }

  .genero_txt {
    color: #61708b;
    font-size: 14px;
    font-weight: 400;
    margin-right: 18px;
  }

  .edit_container {
    border-left: 1px solid #e3e7ec;
    padding: 8px;
    cursor: pointer;
  }
}

.block_user_back_red {
  background: #ffe3e3;
}
