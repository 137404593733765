@import "../../styles/colors";

.button {
  font-weight: 600;
  font-size: 15px;
  padding: 12px 24px;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
}

.primary-button {
  background: $verde-siamo;
  color: #ffffff;
  border-radius: 4px;
}

.secondary-button {
  color: $verde-siamo;
}

.disable-button {
  background: $grey-disabled;
  cursor: not-allowed;
}

.add-familiar {
  text-align: center;
}
