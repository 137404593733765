@import "colors";

.container {
  max-width: 370px;
  padding: 0 10px;
  margin: auto;
  font-family: Roboto;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.isLoading {
  opacity: 0.5;
}

.loading-img {
  position: absolute;
  width: 100px;
  top: 300px;
}

h2 {
  color: $verde-siamo;
  font-size: 24px;
  font-weight: 500;
  margin: 0 0 20px 0;
  text-align: center;

  .via {
    display: block;
  }

  .via-judicial {
    color: #dc2f2c;
  }

  .via-administrativa {
    color: #2f8e6f;
  }

  &.sub-title {
    font-weight: 300;
    margin-bottom: 5px;
  }
}

.resultado-via {
  color: $grey-siamo;
}

p {
  color: $grey-siamo;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin: 0 0 20px 0;

  .bold {
    font-weight: 700;
  }
}

.header {
  display: flex;
  width: 100%;
  margin: 20px 0 36px 0;

  h1 {
    margin: 0 auto;
    position: relative;

    &.extra-margin {
      right: 9px;
    }
  }

  .back {
    align-self: center;
  }
}

.box-questions {
  text-align: center;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(139, 147, 162, 0.6),
    0px 0px 1px 0px rgba(139, 147, 162, 0.2);
  padding: 20px 15px;
  color: $grey-siamo;
  margin-bottom: 32px;

  h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 10px 0;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    text-align: left;
    padding: 0 30px;
  }

  .btn-secondary {
    color: $verde-siamo;
    font-size: 12px;
    font-weight: 600;
    padding: 6px 18px;
    text-transform: uppercase;
    border-radius: 4.16px;
    border: 1.04px solid $verde-siamo;
    display: inline-block;
    margin-top: 8px;
    text-decoration: none;
  }
}

.bottom-container {
  margin-top: 40px;
}

.faq-warning {
  display: flex;
  align-items: flex-start;
  margin-bottom: 53px;

  .faq-text {
    color: $grey-siamo;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    margin-left: 12px;
  }
}

.underline {
  font-weight: 700;
  text-decoration: underline;
  color: $grey-siamo;

  a {
    color: $grey-siamo;
  }
}

.creators {
  font-size: 11px;
  text-align: center;
  margin: auto 0;
  padding-bottom: 6px;
}

.faq-section {
  p,
  span {
    text-align: left;
  }

  .no-margin-bottom {
    margin-bottom: 0;
  }

  a {
    color: $grey-siamo;
    font-weight: 700;
  }

  .question {
    font-weight: 700;
    font-size: 16px;
    color: $grey-siamo;
    align-self: flex-start;
    margin-bottom: 6px;
  }

  ul {
    padding-left: 26px;

    li {
      color: $grey-siamo;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }
}

.user-list-container {
  width: 100%;
  overflow: scroll;
  padding-bottom: 30px;
}

.arrow_down {
  margin: 9px 0 10px 0;
  text-align: center;
}

.footer-comprobar {
  background: #ffffff;
  box-shadow: 0px 1.04px 1.04px 0px rgba(139, 147, 162, 0.21),
    0px 3.12px 5.2px 3.12px rgba(139, 147, 162, 0.25);
  height: 60px;
  width: 100%;
  padding: 0 16px;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.footer-new-arbol {
  margin-top: 32px;
  text-align: center;
  width: 100%;

  p {
    text-align: left;
  }
}

.volver_a_comprobar {
  display: flex;
  justify-content: space-between;
  padding: 0;

  .button {
    padding: 12px 8px;
    font-size: 13px;
    margin: 0 8px;
  }
}
