.modal {
  header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    padding: 13px 15px 11px 15px;
    height: 24px;

    h2 {
      color: #374561;
      font-size: 16px;
      font-weight: 600;
      margin: 4px 0 0 0;
    }

    .close {
      cursor: pointer;
      position: relative;
      bottom: 3px;
    }
  }

  .fields-content {
    padding: 20px 16px 0 16px;
    position: relative;

    .field {
      margin-bottom: 16px;
      height: 60px;
    }

    .error-field {
      margin: 0;
      position: absolute;
      bottom: -14px;
      left: 5px;
    }
  }

  footer {
    display: flex;
    justify-content: center;
    padding: 8px 16px 16px 16px;
  }
}
