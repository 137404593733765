.emptyblock {
    width: 96%;
    height: 45px;
    color: #CED3D6;
    font-weight: 500;
    font-size: 16px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px dashed #D6D6D6;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}